class suggestionListComponent extends Component {

    static name() {
        return "suggestionListComponent";
    }

    static componentName() {
        return "suggestionListComponent";
    }

    data(){
        return{
            viewmode: 'module',
            orderSelected:"",
            suggestionsList : []
        };
    }

    mounted() {
        return async function () {
            this.fetchData();
        };
    }

    getWatch() {
        return {
            '$store.state.appState':'fetchData',
            '$store.state.suggestionList':'fetchData',
            '$store.state.orderBy':'fetchData',
            '$store.state.orderDirection':'fetchData'
        };
    }

    getComputed() {
        return {
            initItem: function () {
                return this.$store.getters.getItemPerPage * this.$store.getters.getOffset;
            },
            lastItem: function () {
                return this.$store.getters.getItemPerPage * this.$store.getters.getOffset + this.$store.getters.getItemPerPage;
            },
            moduleObject: function () {
                return {
                    'green-text': this.viewmode == 'module'
                };
            },
            listObject: function () {
                return {
                    'green-text': this.viewmode == 'list'
                };
            }
        };
    }

    getMethods() {
        return {
            fetchData:this.fetchData,
            openItemView:this.openItemView,
            getItemByCode:this.getItemByCode,
            reorderList:this.reorderList,
        };
    }

    fetchData(){
        this.suggestionsList=[];
        for(let itemCode of this.$store.getters.getSuggestions){
            let itemLoad = this.$store.getters.getItemByCode(itemCode);
            if(itemLoad)
                this.suggestionsList.push(itemLoad)
        }
        this.suggestionsList = __lodash__.orderBy(this.suggestionsList, this.$store.getters.getOrderBy,this.$store.getters.getOrderDirection);
    }

    openItemView(item){
        if(item)
            this.$router.push(`/view/${item.Code}`);
    }

    getItemByCode(itemCode){
        return this.$store.getters.getItemByCode(itemCode);
    }

    reorderList () {
        this.$store.dispatch('reorderItems',this.orderSelected);
    }

    getTemplate() {
        return `<div class="produktliste">
                    <div class="header-produktliste">
                        <h4>{{tr('Suggested List')}} ({{suggestionsList.length}})</h4>
                         <div class="order-options">
                            <span>{{tr('Order by')}}</span>
                            <select id="soflow" v-model="orderSelected" class="minimal browser-default" @change="reorderList">
                                <option disabled value="">{{tr('Select One')}}</option>
                                <template v-for="orderField of store.getters.getSetting.ItemOrdered">
                                    <option :value="orderField.fields.internalId" :selected="orderField==orderSelected">{{tr(orderField.fields.ItemField)}}
                                        <templae >
                                        ({{orderField.fields.OrderDirection}})
                                    </option>
                                </template>
                            </select>
                        </div>
                        <div class="view-options">
                            <span @click="viewmode='module'" v-bind:class="moduleObject">
                                <span>Mosaico</span>
                                <i class="fas fa-th-large fa-1x"></i>
                                <!--<img src="../static/ecommerce/assets/img/mosaico.jpg" width="20" height="22" alt="">-->
                            </span>
                            <span @click="viewmode='list'" v-bind:class="listObject">
                                <span >Lista</span>
                                <i class="fas fa-list-alt fa-1x"></i>
                                <!--<img src="../static/ecommerce/assets/img/list.jpg" width="21" height="22" alt="">-->
                            </span>
                        </div>
                        <template v-if="suggestionsList.length>0">
                            <paginationComponent currentView="suggestions"></paginationComponent>
                        </template>
                    </div>
                    <div class="produktliste">
                         <template v-if="$store.getters.getAppState!='ready'">
                                <template v-if="filteredItems.length==0">
                                    <div class="container center section white" >
                                        <h3>{{tr('Please wait. Loading ...')}}</h3>
                                    </div>
                                </template>
                         </template>
                         <template v-else>
                            <template v-for="item in suggestionsList.slice(initItem,lastItem)">
                                <div :class="{col:true, 's12': viewmode=='list', 's6 m6 l3': viewmode=='module'}" >
                                      <template v-if="viewmode=='list'">
                                          <itemViewListComponent :item="item" @click="openItemView(item)"></itemViewListComponent>
                                      </template>
                                      <template v-else>
                                          <itemViewModuleComponent :item="item" @click="openItemView(item)"></itemViewModuleComponent>
                                      </template>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>`;
    }
}

suggestionListComponent.registerComponent();